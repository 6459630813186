var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"role-manage page"},[_c('gc-header',{attrs:{"header-data":_vm.pageConfig.pageHeader},scopedSlots:_vm._u([{key:"ops",fn:function(){return [_c('el-button',{directives:[{name:"has",rawName:"v-has",value:(_vm.permission.add),expression:"permission.add"}],attrs:{"type":"primary"},on:{"click":_vm.handleAddClick}},[_vm._v(_vm._s(_vm.pageConfig.addBtnText))])]},proxy:true}])}),_c('div',{directives:[{name:"loading",rawName:"v-loading.lock",value:(_vm.loading),expression:"loading",modifiers:{"lock":true}}],staticClass:"page-main"},[(_vm.isEmpty)?_c('div',{staticClass:"page-main-right"},[_c('gc-empty',{attrs:{"page-type":_vm.pageConfig.emptyPageName}})],1):[_c('div',{staticClass:"page-main-left"},[_c('gc-sub-title',{staticStyle:{"margin-bottom":"30px","margin-left":"22px"},attrs:{"icon":'icon-jiaose',"text":_vm.pageConfig.subTitleText}}),_vm._l((_vm.roleList),function(item){return _c('div',{key:item.id,staticClass:"role-item",class:{ active: _vm.checkedId === item.id },on:{"click":function($event){return _vm.handleRoleCheck(item)}}},[_c('div',{staticClass:"role-name"},[_vm._v(_vm._s(item.name))]),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"角色来源于模板","placement":"top"}},[(item.isFromTemplate)?_c('i',{staticClass:"iconfont icon-yellow icon-laizimoban"}):_vm._e()]),_c('div',{staticClass:"split-block"}),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"复制角色","placement":"top"}},[_c('i',{directives:[{name:"has",rawName:"v-has",value:(_vm.permission.add),expression:"permission.add"}],staticClass:"iconfont icon-blue icon-fuzhi",on:{"click":function($event){$event.stopPropagation();return (function (e) {
                  _vm.handleCopyClick(e, item);
                }).apply(null, arguments)}}})]),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"删除角色","placement":"top"}},[_c('i',{directives:[{name:"has",rawName:"v-has",value:(_vm.permission.delete),expression:"permission.delete"}],staticClass:"iconfont icon-blue icon-shanchu",on:{"click":function($event){$event.stopPropagation();return (function (e) {
                  _vm.handleDeleteClick(e, item);
                }).apply(null, arguments)}}})])],1)}),(_vm.status === 'copy' || _vm.status === 'add')?_c('div',{staticClass:"role-item active"},[_c('div',{staticClass:"role-name"},[_vm._v("未命名")]),_c('div',{staticClass:"split-block"})]):_vm._e()],2),_c('div',{staticClass:"page-main-right"},[_c('gc-sub-title',{staticStyle:{"margin-bottom":"0","margin-left":"22px"},attrs:{"icon":"icon-quanxian ","text":'角色权限'}},[(
              ['edit', 'copy', 'add'].includes(_vm.status) &&
              _vm.$has([_vm.permission.add, _vm.permission.edit])
            )?[_c('el-button',{attrs:{"plain":"","round":"","size":"large"},on:{"click":_vm.handleSaveClick}},[_vm._v("保存")]),_c('el-button',{staticStyle:{"margin-right":"24px"},attrs:{"round":"","size":"large"},on:{"click":_vm.handleCancelClick}},[_vm._v("取消")])]:[(_vm.$has(_vm.permission.edit))?_c('el-button',{attrs:{"type":"primary","round":"","size":"large"},on:{"click":_vm.handleEditClick}},[_vm._v("编辑")]):_vm._e(),(_vm.$has(_vm.permission.delete))?_c('el-button',{staticStyle:{"margin-right":"24px"},attrs:{"type":"warning","round":"","plain":"","size":"large"},on:{"click":function (e) {
                  _vm.handleDeleteClick(e, _vm.checkedRole);
                }}},[_vm._v("删除")]):_vm._e()]],2),_c('div',{staticClass:"pale-title"},[_vm._v("基础信息")]),_c('div',{staticClass:"model-form"},[(_vm.status !== 'view')?_c('el-form',{ref:"modelForm",class:_vm.pageType,attrs:{"rules":_vm.modelRules,"model":_vm.model}},[_c('el-form-item',{attrs:{"prop":"name"}},[_c('el-input',{attrs:{"size":"small","placeholder":"请填写"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.stopPropagation();return false;}},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}},[_c('template',{slot:"prepend"},[_vm._v(_vm._s(_vm.pageConfig.modelItemName))])],2)],1)],1):_c('div',{staticClass:"basic-info"},[_vm._v(" 角色名称："),_c('span',{staticClass:"role-name"},[_vm._v(_vm._s(_vm.model.name))])])],1),_c('div',{staticClass:"pale-title"},[_vm._v("权限分配")]),_c('el-tree',{ref:"roleTree",attrs:{"show-checkbox":"","node-key":"id","highlight-current":"","data":_vm.treeData,"check-on-click-node":true,"default-expand-all":true,"default-checked-keys":_vm.checkedIds,"props":_vm.defaultProps}})],1)]],2),_c('apply-role',{ref:"applyDialog",on:{"on-apply-template":_vm.handleApplyTemplate}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }