<template>
  <div class="role-manage page">
    <gc-header :header-data="pageConfig.pageHeader">
      <template #ops>
        <el-button
          type="primary"
          @click="handleAddClick"
          v-has="permission.add"
          >{{ pageConfig.addBtnText }}</el-button
        >
      </template>
    </gc-header>
    <div class="page-main" v-loading.lock="loading">
      <div class="page-main-right" v-if="isEmpty">
        <gc-empty :page-type="pageConfig.emptyPageName" />
      </div>
      <template v-else>
        <div class="page-main-left">
          <gc-sub-title
            :icon="'icon-jiaose'"
            :text="pageConfig.subTitleText"
            style="margin-bottom: 30px; margin-left: 22px"
          />
          <div
            class="role-item"
            v-for="item in roleList"
            :key="item.id"
            :class="{ active: checkedId === item.id }"
            @click="handleRoleCheck(item)"
          >
            <div class="role-name">{{ item.name }}</div>
            <el-tooltip
              class="item"
              effect="dark"
              content="角色来源于模板"
              placement="top"
            >
              <i
                class="iconfont icon-yellow icon-laizimoban"
                v-if="item.isFromTemplate"
              />
            </el-tooltip>

            <div class="split-block" />
            <el-tooltip
              class="item"
              effect="dark"
              content="复制角色"
              placement="top"
            >
              <i
                v-has="permission.add"
                class="iconfont icon-blue icon-fuzhi"
                @click.stop="
                  (e) => {
                    handleCopyClick(e, item);
                  }
                "
              />
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="删除角色"
              placement="top"
            >
              <i
                v-has="permission.delete"
                class="iconfont icon-blue icon-shanchu"
                @click.stop="
                  (e) => {
                    handleDeleteClick(e, item);
                  }
                "
              />
            </el-tooltip>
          </div>
          <div
            class="role-item active"
            v-if="status === 'copy' || status === 'add'"
          >
            <div class="role-name">未命名</div>
            <div class="split-block" />
            <!-- <i class="iconfont icon-blue icon-fuzhi" />
          <i class="iconfont icon-blue icon-shanchu" /> -->
          </div>
        </div>
        <div class="page-main-right">
          <gc-sub-title
            icon="icon-quanxian "
            :text="'角色权限'"
            style="margin-bottom: 0; margin-left: 22px"
          >
            <template
              v-if="
                ['edit', 'copy', 'add'].includes(status) &&
                $has([permission.add, permission.edit])
              "
            >
              <el-button plain round size="large" @click="handleSaveClick"
                >保存</el-button
              >
              <el-button
                round
                size="large"
                style="margin-right: 24px"
                @click="handleCancelClick"
                >取消</el-button
              >
            </template>
            <template v-else>
              <!-- v-has="permission.edit" -->
              <el-button
                type="primary"
                round
                size="large"
                v-if="$has(permission.edit)"
                @click="handleEditClick"
                >编辑</el-button
              >
              <!-- v-has="permission.delete" -->
              <el-button
                v-if="$has(permission.delete)"
                type="warning"
                round
                plain
                size="large"
                style="margin-right: 24px"
                @click="
                  (e) => {
                    handleDeleteClick(e, checkedRole);
                  }
                "
                >删除</el-button
              >
            </template>
          </gc-sub-title>
          <div class="pale-title">基础信息</div>
          <div class="model-form">
            <el-form
              v-if="status !== 'view'"
              ref="modelForm"
              :rules="modelRules"
              :model="model"
              :class="pageType"
            >
              <el-form-item prop="name">
                <el-input
                  size="small"
                  placeholder="请填写"
                  v-model="model.name"
                  @keyup.enter.native.stop="return false;"
                >
                  <template slot="prepend">{{
                    pageConfig.modelItemName
                  }}</template>
                </el-input>
              </el-form-item>
            </el-form>
            <div class="basic-info" v-else>
              角色名称：<span class="role-name">{{ model.name }}</span>
            </div>
          </div>

          <div class="pale-title">权限分配</div>
          <el-tree
            ref="roleTree"
            show-checkbox
            node-key="id"
            highlight-current
            :data="treeData"
            :check-on-click-node="true"
            :default-expand-all="true"
            :default-checked-keys="checkedIds"
            :props="defaultProps"
          >
          </el-tree>
        </div>
      </template>
    </div>
    <apply-role
      ref="applyDialog"
      @on-apply-template="handleApplyTemplate"
    ></apply-role>
  </div>
</template>

<script>
import ApplyRole from "./components/ApplyRole.vue";
import Config from "./config";
import { apiGetTemplateRoleContent } from "@/api/system";

export default {
  name: "RoleManage",
  components: {
    ApplyRole,
  },
  props: {},
  data() {
    return {
      roleList: [],
      organizationOptions: [],
      model: {
        name: "",
      },
      modelRules: {
        name: [
          {
            validator: (rule, value, callback) => {
              let val = this.model.name;
              if (val == null || val.toString().trim() == "") {
                callback(this.pageConfig.modelItemName + "不能为空");
                return;
              }
              if (val.toString().trim().length > 20) {
                callback("请勿超过20字数");
                return;
              }
              callback();
            },
            trigger: ["change", "blur"],
          },
        ],
      },
      checkedIds: [],
      initialCheckedIds: [],
      treeData: [],
      defaultProps: {
        children: "children",
        label: "name",
      },
      status: "view", // view：仅查看，edit：编辑，copy：从角色复制
      checkedRole: {},
      loading: false,
      pageType: "templateRole",
    };
  },
  computed: {
    permission() {
      return Config[`${this.pageType}Permission`];
    },
    pageConfig() {
      return Config[this.pageType];
    },
    isEmpty() {
      return (
        this.roleList.length === 0 && !["copy", "add"].includes(this.status)
      );
    },
    checkedId() {
      return this.checkedRole.id || "";
    },
    userInfo() {
      return this.$store.getters.userInfo;
    },
  },
  watch: {},
  created() {
    this.pageType =
      this.$route.path === "/organize/role-manage" ? "role" : "templateRole";
  },
  activated() {
    this.getInitialTreeContent();
    this.getList();
  },
  methods: {
    getInitialTreeContent() {
      this.pageConfig
        .apiGetInitialTree({ namespaces: "utos,iot-monitor" })
        .then((res) => {
          this.treeData = res.children;
        });
    },
    // roleList check item
    handleRoleCheck(item) {
      this.checkedRole = item;
      this.model.name = item.name;
      this.status = "view";
      this.getRoleContent(item.id, true);
      // this.toggleTreeStatus(true);
    },
    getRoleContent(roleId, disabled = true, add = false) {
      const { userLevel, tenantId } = this.userInfo;

      const query = {
        isBusiness: true,
        roleId,
        namespaces: "utos,iot-monitor",
      };
      if (userLevel !== 0) {
        query.tenantId = tenantId || "";
        if (add) {
          query.refType = "REF";
        }
      }
      this.pageConfig.apiGetContent(query).then((res) => {
        this.checkedIds = res;
        this.initialCheckedIds = this._.cloneDeep(res);
        this.treeData?.length > 0 && this.$refs.roleTree.setCheckedKeys(res);
        this.toggleTreeStatus(disabled);
      });
    },
    // get role from template to edit
    handleApplyTemplate({ id, name }) {
      this.checkedRole = {};
      this.$set(this.model, "name", name);
      this.status = "copy";
      this.toggleTreeStatus(false);
      if (id === 0) {
        // 带入初始模板
        this.checkedIds = [];
        this.$refs.roleTree.setCheckedKeys([]);
      } else {
        apiGetTemplateRoleContent({
          roleId: id,
          namespaces: "utos,iot-monitor",
        }).then((res) => {
          this.checkedIds = res;
          this.$refs.roleTree.setCheckedKeys(res);
        });
      }
    },
    // get role/templateRole list
    getList() {
      this.loading = true;
      this.pageConfig
        .apiGetList()
        .then(({ dataList }) => {
          if (!dataList || dataList.length === 0) return (this.roleList = []);
          this.roleList = dataList;
          this.handleRoleCheck(this.roleList[0]);
        })
        .catch((err) => {
          this.$message.error(err?.message || "获取列表失败");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // add / edit
    handleSaveClick() {
      const req =
        this.status === "edit"
          ? this.pageConfig.apiModify
          : this.pageConfig.apiAdd;
      const keys = this.$refs.roleTree.getCheckedKeys(true);
      this.$refs.modelForm.validate((valid) => {
        if (!valid) return;
        if (keys.length === 0) return this.$message.warning("权限不能为空");
        const query = {
          name: this.model.name,
          functionalIds: keys,
        };
        this.status === "edit" && (query.id = this.checkedRole.id);
        req(query)
          .then(() => {
            const msg = this.status === "edit" ? "修改成功" : "新增成功";
            this.$message.success(msg);
            this.getList();
            this.status = "view";
          })
          .catch((err) => {
            console.log("add templateRole/ role err: ", err);
            this.$message.error(err?.message || "操作失败");
          });
      });
    },
    handleCancelClick() {
      if (["add", "copy"].includes(this.status)) {
        // 复制角色状态，取消后获取roleList第一项的role content
        this.roleList.length && this.handleRoleCheck(this.roleList[0]);
      } else {
        this.status = "view";
        this.checkedIds = this._.cloneDeep(this.initialCheckedIds);

        this.$refs.roleTree.setCheckedKeys(this.initialCheckedIds);
        this.toggleTreeStatus(true);
      }
    },
    handleDeleteClick(e, { id }) {
      this.$confirm("删除后数据将无法恢复", "确定删除角色吗？").then(() => {
        this.pageConfig.apiDelete(id).then((res) => {
          this.$message.success("删除成功");
          this.checkedRole = {};
          this.getList();
        });
      });
      e.stopPropagation();
    },
    // 新增角色、新增模板角色
    handleAddClick() {
      if (this.status === "add") return;

      if (this.pageType === "role") {
        this.$refs.applyDialog.showDialog();
      } else {
        this.checkedRole = {};
        this.model = {};
        this.checkedIds = [];
        this.$refs.roleTree.setCheckedKeys([]);
        this.status = "add";
        this.toggleTreeStatus(false);
      }

      // this.getRoleContent(0, false, true);
    },
    // 复制角色：获取角色内容反显并可编辑
    handleCopyClick(e, { id, name }) {
      this.$set(this.model, "name", "");
      this.checkedRole = {};
      this.status = "copy";
      this.getRoleContent(id, false);
      e.stopPropagation();
    },
    // 预览状态的编辑：将treeData的disabled置为false
    handleEditClick() {
      this.status = "edit";
      this.toggleTreeStatus(false);
    },
    // 改变tree状态为可编辑与不可编辑
    toggleTreeStatus(disabled) {
      const helper = (list, disabled) => {
        list.forEach((item) => {
          this.$set(item, "disabled", disabled);
          item.disabled = disabled;
          item.children && helper(item.children, disabled);
        });
      };
      helper(this.treeData, disabled);
    },
    handleApplyTemplateRoleClick() {
      this.$refs.applyDialog.showDialog("");
    },
  },
};
</script>
<style lang="scss" scoped>
.page-main-left {
  padding-left: 0;
  padding-right: 0;
  .role-item {
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px 0 22px;
    font-size: 14px;
    box-sizing: border-box;
    &.active,
    &:hover {
      background: #ebedfa;
      .role-name {
        color: $base-color-primary;
      }
    }
    .role-name {
      color: #666666;
      margin-right: 10px;
    }
    .split-block {
      flex: 1;
    }
    .iconfont {
      font-size: 12px;
      &.icon-yellow {
        color: #ffd633;
      }
      &.icon-blue {
        padding-left: 17px;
        color: $base-color-primary;
      }
    }
  }
}
.page-main-right {
  padding-left: 0;
  padding-right: 0;
  display: flex;
  flex-direction: column;
  .pale-title {
    margin: 32px 0 18px 27px;
    font-size: 14px;
    color: #ababab;
    line-height: 21px;
  }
  .basic-info {
    font-size: 14px;
    color: #666666;
    line-height: 21px;
    .role-name {
      padding-left: 10px;
      color: #333333;
    }
  }
  .model-form {
    margin: 0 0 3px 27px;
    display: flex;
    align-items: center;
  }
}
::v-deep .el-tree {
  flex: 1;
  padding-left: 24px;
  overflow: scroll;
  .el-tree-node__content {
    // padding-left: 24px;
  }

  .el-checkbox {
    margin-left: 10px;
    margin-right: 16px;
  }
}
::v-deep .el-form {
  .el-form-item {
    margin-bottom: 8px;
  }
  &.role {
    .el-form-item__error {
      padding-left: 92px;
    }
  }
  &.templateRole {
    .el-form-item__error {
      padding-left: 122px;
    }
  }

  .el-input {
    width: 300px;
    height: 40px;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid #cccccc;
    overflow: hidden;
    .el-input-group__prepend {
      position: relative;
      padding: 0 14px;
      font-size: 14px;
      color: #666666;
      background: transparent;
      border: none;
      box-sizing: border-box;
      &::after {
        position: absolute;
        margin-left: 14px;
        content: "";
        height: 16px;
        border-right: 1px solid #9ea8a3;
        display: inline-block;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .el-input__inner {
      border: none;
      height: 38px;
      line-height: 38px;
    }
  }
}
</style>
